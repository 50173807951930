import React from "react";
import { TextField, Grid, Typography } from "@mui/material";

// ======================================|| STOCK EDIT MODAL TEXTFIELD ||========================================== //

export const StockModalTextfield = ({
  typographyText,
  value,
  disabled,
  handleChange,
  errorText,
  fieldStyle,
}) => {
  return (
    <Grid container spacing={5} className="order-details-modal-table">
      <Grid item xs={5}>
        <Typography className="bill-modal-text">{typographyText}</Typography>
      </Grid>

      <Grid item xs={7}>
        <TextField
          label={!disabled ? typographyText : ""}
          value={value}
          disabled={disabled}
          onChange={(e) => handleChange(e)}
          style={fieldStyle}
          error={errorText !== ""}
          helperText={errorText}
          FormHelperTextProps={{
            style: { color: "var(--common-error-color)" },
          }}
        />
      </Grid>
    </Grid>
  );
};
