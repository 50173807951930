import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";

// ============================================|| PARTY SELECT ||============================================ //

export const PartySelect = ({
  selectedParty,
  setSelectedParty,
  selectedCategory,
  isDisableFilter,
}) => {
  const { customerList } = useSelector((state) => state.data_menu);

  const {
    placeHolderTexts: { chooseCustomerText },
  } = uiTexts;

  return (
    <Autocomplete
      className="auto-complete-comp-width"
      sx={{
        "& .MuiInputBase-root": {
          height: "55px",
        },
      }}
      value={
        selectedParty?.name
          ? customerList?.find(
              (party) =>
                party.partyName === selectedParty.name &&
                party.partyId === selectedParty.id,
            )
          : null
      }
      onChange={(event, newValue) =>
        setSelectedParty(
          newValue
            ? { id: newValue.partyId, name: newValue.partyName }
            : { id: "", name: "" },
        )
      }
      options={customerList}
      getOptionLabel={(option) =>
        `${option.partyName}${option.areaName ? ` - ${option.areaName}` : ""}`
      }
      renderOption={(props, option) => (
        <li {...props} key={option.partyId}>
          {`${option.partyName}${
            option.areaName ? ` - ${option.areaName}` : ""
          }`}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={chooseCustomerText} />
      )}
      disabled={isDisableFilter}
    />
  );
};
