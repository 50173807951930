import React, { useState, useEffect } from "react";
import { Dialog, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";
import { BillingDetails } from "./Bill Details/BillingDetails";
import { getNetAmount, renderGatePassData } from "../../Utils/common";
import dayjs from "dayjs";
import {
  getOrderItemTransactionById,
  moveToBill,
  getAllCustomerPendingOrders,
} from "../../Services/OrderService";
import {
  CustomerPendingOrders,
  TransportFareData,
} from "../../Store/Reducers/data_menu";
import { TransportingDetails } from "./Transport Details/TransportingDetails";
import { BillDialogTitle } from "../../CommonComp/BillDialogTitle";
import { getUserName, capitalizeFirstLowercaseRest } from "../../Utils/common";
import { calculateTotalAmount } from "../../Utils/common";

// ===================================================|| BILL MODAL ||=================================================== //

export const BillModal = ({
  customerDetailsSelect,
  setCustomerDetailsSelect,
  selectedCustomer,
  setSelectedCustomer,
  billClick,
  setBillClick,
  orderItems,
  setOrderItems,
  setIsOpenAlert,
  partyGroups,
  setPartyGroups,
}) => {
  const {
    labels: { billText },
    buttonText: { noText },
    placeHolderTexts: { dateAndTimeInputFormat },
  } = uiTexts;

  const dispatch = useDispatch();

  //Filter the selected values from all
  const filteredDetails = orderItems?.filter((item) =>
    customerDetailsSelect.includes(item.id),
  );

  const filteredOrders = [...filteredDetails];

  const [dummyOrder, setDummyOrder] = useState(
    filteredDetails?.map((item) => ({
      ...item,
      taxAmount: 0,
      netAmount: 0,
      deliveryRate: 0,
      fareAmount: 0,
      netFareAmount: 0,
    })),
  );

  const loginUserName = capitalizeFirstLowercaseRest(getUserName());

  const [billNumber, setBillNumber] = useState("");
  const [selectedFirm, setSelectedFirm] = useState("");
  const [userComments, setUserComments] = useState("");
  const [billUpdateError, setBillUpdateError] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [updateClick, setUpdateClick] = useState(false);
  const [updateDate, setUpdateDate] = useState(false);
  const [isArrowIcon, setIsArrowIcon] = useState(false);
  const [customerResponsibility, setCustomerResponsibility] = useState(noText);
  const [selectedTransport, setSelectedTransport] = useState();
  const [modifiedParty, setModifiedParty] = useState(null);
  const [gatePassData, setGatePassData] = useState();
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    setGatePassData(renderGatePassData(dummyOrder));
  }, [dummyOrder]);

  const handleUpdate = async () => {
    try {
      const totalAmount = calculateTotalAmount(dummyOrder, (order) =>
        getNetAmount(order),
      );
      const totalTaxAmount = calculateTotalAmount(dummyOrder, (order) =>
        Number(order.taxAmount),
      );
      const totalNetAmount = calculateTotalAmount(dummyOrder, (order) =>
        getNetAmount(order, true),
      );
      const totalNetFareAmount = calculateTotalAmount(dummyOrder, (order) =>
        Number(order.netFareAmount),
      );

      const updateData = {
        partyId: modifiedParty
          ? modifiedParty.partyId
          : selectedCustomer.partyId,
        billNo: billNumber,
        firmId: selectedFirm,
        date: !updateDate
          ? selectedDate.format(dateAndTimeInputFormat)
          : selectedDate,
        totalAmount: totalAmount,
        totalTaxAmount: totalTaxAmount,
        totalNetAmount: totalNetAmount,
        totalNetFareAmount: totalNetFareAmount,
        userName: loginUserName,
        remarks: userComments,
        orderItemTransactionIds: dummyOrder.map((data) => data.id),
        details: dummyOrder.map((order, index) => ({
          orderItemTransactionId: order.id,
          bag: order.quantityInKg === null ? Number(order.pendingBag) : null,
          rate: Number(order.rate),
          amount: getNetAmount(order),
          taxAmount: Number(order.taxAmount),
          netAmount: getNetAmount(order, true),
          quantityInKg:
            order.quantityInKg === null ? null : Number(order.quantityInKg),
          fareAmount: order.fareAmount,
          netFareAmount: order.netFareAmount,
          deliveryRate: order.deliveryRate,
        })),
        transportId: selectedTransport?.id || null,
        gatePassList: gatePassData?.map((gatePass) => ({
          orderItemTransactionId: gatePass.orderId,
          gatePassDetails: gatePass?.gatePassDetails?.map((details, index) => ({
            id: ++index,
            bag: details.quantity,
            vehicleNo: details.vehicleNumber,
            driverName: "",
            dispatchedOrNot: false,
          })),
        })),
      };

      const res = await moveToBill(updateData);

      if (res.status === 200) {
        const { data, status } = await getAllCustomerPendingOrders();
        if (status === 200) {
          dispatch(CustomerPendingOrders(data));

          const updatedDetails = data.find(
            (res) => res.partyId === selectedCustomer?.partyId,
          );

          setSelectedCustomer(updatedDetails);

          const orderItemsPromises = updateData.orderItemTransactionIds.map(
            async (id) => {
              const result = await getOrderItemTransactionById(id);
              return result.data;
            },
          );

          const results = await Promise.all(orderItemsPromises);

          // Update the orders
          const resultsMap = new Map(results.map((item) => [item.id, item]));

          const updatedOrderItems = orderItems.map((order) => {
            if (resultsMap.has(order.id)) {
              return { ...order, ...resultsMap.get(order.id) };
            }

            return order;
          });

          setOrderItems(updatedOrderItems);
        }
        setIsOpenAlert(true);
      } else {
        console.error("Failed to move to bill", res);
      }

      setCustomerDetailsSelect([]);
      handleCancel();
    } catch (error) {
      setBillUpdateError(error.response.data);
      console.error("Error during handleUpdate:", error);
    }
  };

  const handleCancel = () => {
    setBillNumber("");
    setSelectedFirm("");
    setUserComments("");
    setSelectedDate(null);
    setBillClick(false);
    setUpdateDate(false);
    setModifiedParty(null);
    setCustomerResponsibility(noText);
    setSelectedTransport("");
    setIsArrowIcon(false);
    setPartyGroups({ data: [], status: null });
    setBillUpdateError("");
    dispatch(TransportFareData([]));
  };

  const billingCommonProps = {
    isArrowIcon,
    setIsArrowIcon,
    billUpdateError,
    setBillUpdateError,
    updateClick,
    setUpdateClick,
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={billClick}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "75rem",
          borderRadius: "20px",
        },
      }}
    >
      <BillDialogTitle onClose={handleCancel} titleLabel={billText} />

      <Divider className="bill-details-divider" />

      {!isArrowIcon ? (
        <BillingDetails
          billingInfo={{
            filteredOrders,
            dummyOrder,
            setDummyOrder,
            billNumber,
            setBillNumber,
            selectedFirm,
            setSelectedFirm,
          }}
          userInfo={{
            userComments,
            setUserComments,
          }}
          appState={{
            selectedDate,
            setSelectedDate,
            updateDate,
            setUpdateDate,
            modifiedParty,
            setModifiedParty,
          }}
          billingCommonProps={billingCommonProps}
          partyGroups={partyGroups}
          setPartyGroups={setPartyGroups}
        />
      ) : (
        <TransportingDetails
          billingCommonProps={billingCommonProps}
          transportInfo={{
            dummyOrder,
            setDummyOrder,
            handleUpdate,
            handleCancel,
            customerResponsibility,
            setCustomerResponsibility,
            selectedTransport,
            setSelectedTransport,
            setGatePassData,
            gatePassData,
            setExpandedRows,
            expandedRows,
            selectedCustomer,
            setBillUpdateError,
          }}
        />
      )}
    </Dialog>
  );
};
