import React from "react";
import { TableHead, TableCell, Typography, TableRow } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";

// =================================|| STOCK RHS CONTENT TABLE HEADER ||===================================== //

export const StockRhsContentTableHeader = () => {
  const {
    labels: {
      brandNameText,
      quantityText,
      sNoText,
      itemNameText,
      dateText,
      unitText,
      cleanCategory,
    },
  } = uiTexts;

  const headers = [
    { label: sNoText },
    { label: dateText },
    { label: itemNameText },
    { label: brandNameText },
    { label: cleanCategory },
    { label: unitText },
    { label: quantityText },
    { label: "" },
    { label: "" },
  ];

  return (
    <TableHead>
      <TableRow>
        {headers?.map((header, index) => (
          <TableCell key={index} className="customer-list-details-divider">
            <Typography className="orders-text-header">
              {header.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
