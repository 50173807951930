import React from "react";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../PaymentTab/PaymentPage.css";

// =========================================|| STOCK UPDATE MODAL HEADER ||======================================== //

export const StockUpdateModalHeader = ({ title, handleClose }) => {
  return (
    <DialogTitle>
      <Typography>{title}</Typography>

      <IconButton
        className="bill-modal-close-iconbutton"
        onClick={() => handleClose()}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
