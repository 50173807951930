import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../Loader/Loader";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import StockLhsContent from "./StockLhsContent/StockLhsContent";
import "../OrdersTab/OrderPage.css";
import "./StockTab.css";

// ================================================|| STOCK PAGE ||================================================= //

function StockTab() {
  const categoryStatusCode = useSelector(
    (state) => state.status_menu.categoryStatusCode,
  );
  const { itemList, brandList, cleanCategoryList, unitList } = useSelector(
    (state) => state.data_menu,
  );

  const {
    retrieveCategories,
    retrieveItems,
    retrieveBrands,
    retrieveCleanCategories,
    retrieveUnits,
  } = useRetrieveFunctions();

  useEffect(() => {
    retrieveCategories();
    retrieveItems();
    retrieveBrands();
    retrieveCleanCategories();
    retrieveUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoader = () => {
    return (
      !categoryStatusCode &&
      itemList?.length === 0 &&
      brandList?.length === 0 &&
      cleanCategoryList?.length === 0 &&
      unitList?.length === 0
    );
  };

  return (
    <>
      <Header />

      {isLoader() ? <Loader /> : <StockLhsContent />}

      <Footer />
    </>
  );
}

export default StockTab;
