import React from "react";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useSelector } from "react-redux";
import { StockRhsContentHeader } from "./StockRhsContentHeader";
import { StockRhsContentDateFilter } from "./StockRhsContentDateFilter";
import { StockRhsContentTable } from "./StockRhsContentTable";
import { uiTexts } from "../../Utils/uiTexts";

// =========================================|| STOCK RHS CONTENT ||============================================= //

export const StockRhsContent = ({
  selectedCategory,
  handleStockDateSelect,
  handleOpenStockUpdateModal,
  filteredDate,
  setFilteredDate,
  handleClearFilter,
  updatedItemData,
  handleOpenEditStockModal,
  handleRemoveStock,
}) => {
  const {
    alertLabels: { noDataText },
  } = uiTexts;

  const categoryWiseStockData = useSelector(
    (state) => state.data_menu.categoryWiseStockData,
  );

  return (
    <Grid item xs={9}>
      <div className="order-list">
        <StockRhsContentHeader
          selectedCategory={selectedCategory}
          handleOpenStockUpdateModal={handleOpenStockUpdateModal}
        />
      </div>

      <div className="order-list">
        <Stack direction="row">
          <StockRhsContentDateFilter
            filteredDate={filteredDate}
            setFilteredDate={setFilteredDate}
            handleStockDateSelect={handleStockDateSelect}
            isDisabled={categoryWiseStockData?.length === 0}
          />

          {filteredDate !== null && filteredDate !== undefined && (
            <IconButton
              onClick={() => handleClearFilter()}
              className="remove-filtered-date-icon"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          )}
        </Stack>
      </div>

      {updatedItemData?.length !== 0 ? (
        <div className="order-list">
          <StockRhsContentTable
            updatedItemData={updatedItemData}
            handleOpenEditStockModal={handleOpenEditStockModal}
            handleRemoveStock={handleRemoveStock}
          />
        </div>
      ) : (
        <Typography className="no-data-available-text">{noDataText}</Typography>
      )}
    </Grid>
  );
};
