/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog, Divider } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { StockUpdateModalHeader } from "./StockUpdateModalHeader";
import { StockUpdateModalDateAndTime } from "./StockUpdateModalDateAndTime";
import { StockUpdateModalDropdown } from "./StockUpdateModalDropdown";
import { StockModalTextfield } from "./StockModalTextfield";
import { StockUpdateModalActionButton } from "./StockUpdateModalActionButton";
import { updateStock } from "../../Services/StockService";
import { useDispatch } from "react-redux";
import { getStockDetailsByCategory } from "../../Services/StockService";
import { CategoryWiseStockData } from "../../Store/Reducers/data_menu";

// ===========================================|| STOCK UPDATE MODAL ||============================================= //

export const StockUpdateModal = ({
  isOpenStockUpdateModal,
  selectedCategory,
  handleCloseStockUpdateModal,
  selectedDate,
  setSelectedDate,
  handleStockDateSelect,
  setSelectedItem,
  selectedItem,
  setSelectedBrand,
  selectedBrand,
  setSelectedCleanCategory,
  selectedCleanCategory,
  setSelectedUnit,
  selectedUnit,
  setStockQuantity,
  stockQuantity,
  setQuantityError,
  quantityError,
  setUpdatedItemData,
  updatedItemData,
  getFilteredItems,
}) => {
  const {
    placeHolderTexts: {
      chooseItem,
      chooseBrand,
      chooseCleanCategory,
      chooseUnit,
      enterNumberOfBags,
      enterKg,
    },
    stockTabKeys: {
      brandIdsText,
      cleanCategoryIdsText,
      unitIdsText,
      itemIdText,
      itemNameText,
      idText,
      brandNameText,
      unitNameText,
      cleanCategoryNameText,
    },
    warnings: { onlyNumbersAreAllowed },
  } = uiTexts;

  const { itemList, brandList, cleanCategoryList, unitList } = useSelector(
    (state) => state.data_menu,
  );
  const dispatch = useDispatch();

  const [filteredItems, setFilteredItems] = useState();
  const [filteredBrand, setFilteredBrand] = useState();
  const [filteredCleanCategory, setFilteredCleanCategory] = useState();
  const [filteredUnit, setFilteredUnit] = useState();
  const [quantity, setQuantity] = useState();

  useEffect(() => {
    const quantity = itemList?.filter(
      (item) => item.itemId === selectedItem?.itemId && item.isRawItem,
    );

    setQuantity(quantity);
  }, [selectedItem]);

  useEffect(() => {
    const filteredItemsInfo = itemList?.filter(
      (item) => item.categoryName === selectedCategory?.categoryName,
    );

    setFilteredItems(filteredItemsInfo);
  }, [selectedCategory]);

  const filterItemsByIds = (
    selectedItem,
    filteredItems,
    idKey,
    list,
    setter,
  ) => {
    const selectedItemData = filteredItems?.find(
      (item) => item.itemId === selectedItem?.itemId,
    );

    if (selectedItemData?.[idKey]?.length > 0) {
      const correspondingItems = selectedItemData[idKey]
        .map((id) => list?.find((listItem) => listItem.id === id))
        .filter(Boolean);

      setter(correspondingItems);
    } else {
      setter([]);
    }
  };

  useEffect(() => {
    filterItemsByIds(
      selectedItem,
      filteredItems,
      brandIdsText,
      brandList,
      setFilteredBrand,
    );
    filterItemsByIds(
      selectedItem,
      filteredItems,
      cleanCategoryIdsText,
      cleanCategoryList,
      setFilteredCleanCategory,
    );
    filterItemsByIds(
      selectedItem,
      filteredItems,
      unitIdsText,
      unitList,
      setFilteredUnit,
    );
  }, [selectedItem, filteredItems]);

  const handleChangeItem = (e) => {
    setSelectedItem(e.target.value);
    setQuantityError("");
    setStockQuantity("");
  };

  const handleFieldChange = (setValue, e) => {
    setValue(e.target.value);
  };

  const handleChangeStockQuantity = (e) => {
    const value = e.target.value;
    setStockQuantity(value);

    if (!/^\d*$/.test(value)) {
      setQuantityError(onlyNumbersAreAllowed);
    } else {
      setQuantityError("");
    }
  };

  const handleUpdateItem = async () => {
    const data = {
      date: selectedDate,
      itemName: selectedItem?.itemName,
      brandName: selectedBrand?.brandName,
      cleanCategoryName: selectedCleanCategory?.cleanCategoryName,
      unitListName: selectedUnit?.unitName,
      totalBags: quantity?.length === 0 ? Number(stockQuantity) : null,
      quantityInKg: quantity?.length > 0 ? Number(stockQuantity) : null,
    };

    await updateStock(data).then(async ({ status }) => {
      if (status === 200) {
        await getStockDetailsByCategory(selectedCategory?.id).then(
          ({ data }) => {
            dispatch(CategoryWiseStockData(data));

            getFilteredItems(data);
          },
        );
      }
    });

    handleCloseStockUpdateModal();
  };

  const isUpdateButtonDisabled = () => {
    return (
      selectedItem === undefined ||
      (filteredBrand?.length > 0 && selectedBrand === undefined) ||
      (filteredCleanCategory?.length > 0 &&
        selectedCleanCategory === undefined) ||
      (filteredUnit?.length > 0 && selectedUnit === undefined) ||
      stockQuantity === "" ||
      quantityError !== ""
    );
  };

  const updateButtonDisabled = isUpdateButtonDisabled();

  return (
    <Dialog
      open={isOpenStockUpdateModal}
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <StockUpdateModalHeader
        title={selectedCategory?.categoryName}
        handleClose={handleCloseStockUpdateModal}
      />

      <Divider />

      <div className="list-container stock-update-modal-height">
        <StockUpdateModalDateAndTime
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleStockDateSelect={handleStockDateSelect}
        />

        <StockUpdateModalDropdown
          typographyText={chooseItem}
          value={selectedItem}
          handleChange={handleChangeItem}
          menuOptions={filteredItems}
          keyId={itemIdText}
          keyValue={itemNameText}
        />

        {filteredBrand?.length > 0 && (
          <StockUpdateModalDropdown
            typographyText={chooseBrand}
            value={selectedBrand}
            handleChange={(e) => handleFieldChange(setSelectedBrand, e)}
            menuOptions={filteredBrand}
            keyId={idText}
            keyValue={brandNameText}
          />
        )}

        {filteredUnit?.length > 0 && (
          <StockUpdateModalDropdown
            typographyText={chooseUnit}
            value={selectedUnit}
            handleChange={(e) => handleFieldChange(setSelectedUnit, e)}
            menuOptions={filteredUnit}
            keyId={idText}
            keyValue={unitNameText}
          />
        )}

        {filteredCleanCategory?.length > 0 && (
          <StockUpdateModalDropdown
            typographyText={chooseCleanCategory}
            value={selectedCleanCategory}
            handleChange={(e) => handleFieldChange(setSelectedCleanCategory, e)}
            menuOptions={filteredCleanCategory}
            keyId={idText}
            keyValue={cleanCategoryNameText}
          />
        )}

        {selectedItem && (
          <StockModalTextfield
            typographyText={quantity?.length > 0 ? enterKg : enterNumberOfBags}
            value={stockQuantity}
            handleChange={handleChangeStockQuantity}
            errorText={quantityError}
            fieldStyle={{ width: "260px" }}
          />
        )}

        <StockUpdateModalActionButton
          handleCloseStockUpdateModal={handleCloseStockUpdateModal}
          updateButtonDisabled={updateButtonDisabled}
          handleUpdateItem={handleUpdateItem}
        />
      </div>
    </Dialog>
  );
};
