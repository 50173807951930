import React from "react";
import { Button, Grid, ListItem, Typography } from "@mui/material";
import "../../OrdersTab/OrderPage.css";

// =============================================|| STOCK LHS LIST ITEM ||============================================ //

export const StockLhsListItem = ({
  selectedCategory,
  handleSelectCategory,
  filteredCategories,
}) => {
  return (
    <div className="list-container order-tab-height">
      {filteredCategories?.map((category, index) => (
        <Button
          className={`orders-payment-content--button ${
            category.id === selectedCategory?.id &&
            "orders-payment-content--button--selected"
          }`}
          key={index}
          onClick={() => handleSelectCategory(category)}
        >
          <ListItem>
            <Grid container>
              <Grid item xs={3}>
                <Typography className="customer-details-list-text">
                  {index + 1}
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography className="customer-details-list-text">
                  {category.categoryName}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </Button>
      ))}
    </div>
  );
};
