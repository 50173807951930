// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  stateNameAll: [],
  itemList: [],
  brandList: [],
  categoryList: [],
  customerList: [],
  analysisData: [],
  analysisDataAll: [],
  salesDataUpload: [],
  registerDataUpload: [],
  orderData: [],
  customerPendingOrders: [],
  firmNames: [],
  orderItemTransactionsData: [],
  customerPendingPayments: [],
  selectedBillDetails: [],
  itemWiseOrderedData: [],
  billsByDateData: [],
  selectedReportData: [],
  selectedFirmName: "",
  selectedCategoryName: "",
  transportData: [],
  dispatchReportData: [],
  salesParametersData: [],
  billsData: [],
  pendingBillsData: [],
  transportFareData: [],
  cleanCategoryList: [],
  unitList: [],
  categoryWiseStockData: [],
};

// ==============================|| SLICE - DATA MENU ||============================== //

const data_menu = createSlice({
  name: "data_menu",
  initialState,
  reducers: {
    StateNameAll(state, action) {
      state.stateNameAll = action.payload;
    },

    ItemList(state, action) {
      state.itemList = action.payload;
    },

    BrandList(state, action) {
      state.brandList = action.payload;
    },

    CategoryList(state, action) {
      state.categoryList = action.payload;
    },

    CustomerList(state, action) {
      state.customerList = action.payload;
    },

    AnalysisData(state, action) {
      state.analysisData = action.payload;
    },

    AnalysisDataAll(state, action) {
      state.analysisDataAll = action.payload;
    },

    SalesDataUpload(state, action) {
      state.salesDataUpload = action.payload;
    },

    RegisterDataUpload(state, action) {
      state.registerDataUpload = action.payload;
    },

    OrderData(state, action) {
      state.orderData = action.payload;
    },

    CustomerPendingOrders(state, action) {
      state.customerPendingOrders = action.payload;
    },

    FirmNames(state, action) {
      state.firmNames = action.payload;
    },

    OrderItemTransactionsData(state, action) {
      state.orderItemTransactionsData = action.payload;
    },

    CustomerPendingPayments(state, action) {
      state.customerPendingPayments = action.payload;
    },

    SelectedBillDetails(state, action) {
      state.selectedBillDetails = action.payload;
    },

    ItemWiseOrderedData(state, action) {
      state.itemWiseOrderedData = action.payload;
    },

    BillsByDateData(state, action) {
      state.billsByDateData = action.payload;
    },

    SelectedReportData(state, action) {
      state.selectedReportData = action.payload;
    },

    SelectedFirmName(state, action) {
      state.selectedFirmName = action.payload;
    },

    SelectedCategoryName(state, action) {
      state.selectedCategoryName = action.payload;
    },

    TransportData(state, action) {
      state.transportData = action.payload;
    },

    DispatchReportData(state, action) {
      state.dispatchReportData = action.payload;
    },

    SalesParametersData(state, action) {
      state.salesParametersData = action.payload;
    },

    BillsData(state, action) {
      state.billsData = action.payload;
    },

    PendingBillsData(state, action) {
      state.pendingBillsData = action.payload;
    },

    TransportFareData(state, action) {
      state.transportFareData = action.payload;
    },

    CleanCategoryList(state, action) {
      state.cleanCategoryList = action.payload;
    },

    UnitList(state, action) {
      state.unitList = action.payload;
    },

    CategoryWiseStockData(state, action) {
      state.categoryWiseStockData = action.payload;
    },
  },
});

export default data_menu.reducer;

export const {
  StateNameAll,
  ItemList,
  BrandList,
  CategoryList,
  CustomerList,
  AnalysisData,
  AnalysisDataAll,
  SalesDataUpload,
  RegisterDataUpload,
  OrderData,
  CustomerPendingOrders,
  FirmNames,
  OrderItemTransactionsData,
  CustomerPendingPayments,
  SelectedBillDetails,
  ItemWiseOrderedData,
  BillsByDateData,
  SelectedReportData,
  SelectedFirmName,
  SelectedCategoryName,
  TransportData,
  DispatchReportData,
  SalesParametersData,
  BillsData,
  PendingBillsData,
  TransportFareData,
  CleanCategoryList,
  UnitList,
  CategoryWiseStockData,
} = data_menu.actions;
