import React, { useState } from "react";
import { Dialog, Divider } from "@mui/material";
import { StockUpdateModalHeader } from "../StockUpdateModal/StockUpdateModalHeader";
import { formatDate } from "../../Utils/common";
import { useDispatch, useSelector } from "react-redux";
import { StockUpdateModalActionButton } from "../StockUpdateModal/StockUpdateModalActionButton";
import { uiTexts } from "../../Utils/uiTexts";
import { StockModalTextfield } from "../StockUpdateModal/StockModalTextfield";
import { editStock } from "../../Services/StockService";
import { CategoryWiseStockData } from "../../Store/Reducers/data_menu";

// ===========================================|| STOCK EDIT MODAL ||============================================= //

export const StockEditModal = ({
  isOpenEditStockModal,
  handleCloseEditStockModal,
  editedItemData,
  setUpdatedItemData,
  updatedItemData,
}) => {
  const {
    labels: {
      dateText,
      itemNameText,
      brandNameText,
      cleanCategory,
      unitText,
      numberOfBags,
      quantityInKg,
      unKnownText,
    },
    warnings: { onlyNumbersAreAllowed },
  } = uiTexts;

  const dispatch = useDispatch();

  const categoryWiseStockData = useSelector(
    (state) => state.data_menu.categoryWiseStockData,
  );

  const [editStockQuantity, setEditStockQuantity] = useState(
    editedItemData?.totalBags || editedItemData?.quantityInKg || "",
  );
  const [editQuantityError, setEditQuantityError] = useState("");

  const handleChangeStockQuantity = (e) => {
    const value = e.target.value;
    setEditStockQuantity(value);

    if (!/^\d*$/.test(value)) {
      setEditQuantityError(onlyNumbersAreAllowed);
    } else {
      setEditQuantityError("");
    }
  };

  const updateItemDataById = (items, itemId, updatedData) => {
    return items?.map((item) =>
      item.id === itemId ? { ...item, ...updatedData } : item,
    );
  };

  const handleEditItem = async () => {
    const quantity = Number(editStockQuantity);

    const data = editedItemData?.totalBags
      ? { totalBags: quantity }
      : { quantityInKg: quantity };

    await editStock(editedItemData?.id, data).then(({ status }) => {
      if (status === 200) {
        //To update redux variable
        const updatedOrder = updateItemDataById(
          categoryWiseStockData,
          editedItemData?.id,
          data,
        );

        //To update state variable
        //State variable is updated independently (filtering may or may not be applied).
        const updatedOrderItems = updateItemDataById(
          updatedItemData,
          editedItemData?.id,
          data,
        );

        dispatch(CategoryWiseStockData(updatedOrder));
        setUpdatedItemData(updatedOrderItems);
      }
    });

    handleCloseEditStockModal();
  };

  const isButtonDisabled = editStockQuantity === "" || editQuantityError !== "";

  return (
    <Dialog
      open={isOpenEditStockModal}
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <StockUpdateModalHeader
        title={editedItemData?.itemName}
        handleClose={handleCloseEditStockModal}
      />

      <Divider />

      <div className="list-container stock-update-modal-height">
        <StockModalTextfield
          typographyText={dateText}
          value={formatDate(editedItemData?.date)}
          disabled={true}
        />

        <StockModalTextfield
          typographyText={itemNameText}
          value={editedItemData?.itemName}
          disabled={true}
        />

        {editedItemData?.brandName !== unKnownText && (
          <StockModalTextfield
            typographyText={brandNameText}
            value={editedItemData?.brandName}
            disabled={true}
          />
        )}

        {editedItemData?.cleanCategoryName !== unKnownText && (
          <StockModalTextfield
            typographyText={cleanCategory}
            value={editedItemData?.cleanCategoryName}
            disabled={true}
          />
        )}

        {editedItemData?.unitListName !== unKnownText && (
          <StockModalTextfield
            typographyText={unitText}
            value={editedItemData?.unitListName}
            disabled={true}
          />
        )}

        <StockModalTextfield
          typographyText={
            editedItemData?.totalBags ? numberOfBags : quantityInKg
          }
          value={editStockQuantity}
          disabled={false}
          handleChange={handleChangeStockQuantity}
          errorText={editQuantityError}
        />

        <StockUpdateModalActionButton
          handleCloseStockUpdateModal={handleCloseEditStockModal}
          updateButtonDisabled={isButtonDisabled}
          handleUpdateItem={handleEditItem}
        />
      </div>
    </Dialog>
  );
};
