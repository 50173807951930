import React from "react";
import { Grid, Typography } from "@mui/material";
import { capitalizeFirstLowercaseRest } from "../../../Utils/common";
import { uiTexts } from "../../../Utils/uiTexts";

// ===================================================|| SOURCE AND DESTINATION ||=================================================== //

export const SourceAndDestinationDetails = ({ selectedCustomer }) => {
  const {
    alertLabels: { noDataText },
    separators: { commaText },
    labels: { sourceText, destinationText, virudhunagarText, tamilNaduText },
  } = uiTexts;

  return (
    <Grid container className="order-details-modal-table">
      <Grid item xs={2.5}>
        <Typography className="bill-modal-text">{sourceText}</Typography>
      </Grid>

      <Grid item xs={3.5}>
        <Typography className="source-destination-text">
          {virudhunagarText}
          {commaText}
          {tamilNaduText}
        </Typography>
      </Grid>

      <Grid item xs={2.5}>
        <Typography className="bill-modal-text">{destinationText}</Typography>
      </Grid>

      <Grid item xs={3.5}>
        {(() => {
          const area = selectedCustomer?.partyArea?.trim();

          const displayText = area
            ? capitalizeFirstLowercaseRest(area)
            : noDataText;

          const textStyle = `source-destination-text ${
            area ? "" : "source-destination-error-text"
          }`;

          return <Typography className={textStyle}>{displayText}</Typography>;
        })()}
      </Grid>
    </Grid>
  );
};
