/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { TransportDetailsTable } from "../../OrdersTab/MoveToBill Modal/Transport Details/Transport Details Content/TransportDetailsTable";
import { TransportDetailsActions } from "../../OrdersTab/MoveToBill Modal/Transport Details/Transport Details Content/TransportDetailsActions";
import { TransportInputFields } from "../../OrdersTab/MoveToBill Modal/Transport Details/Transport Details Content/TransportInputFields";
import { uiTexts } from "../../Utils/uiTexts";
import {
  calculateTotalQuantity,
  getPendingBagOrQuantity,
  getFirstPartUpperCase,
} from "../../Utils/common";
import { SourceAndDestinationDetails } from "../../OrdersTab/MoveToBill Modal/Transport Details/SourceAndDestinationDetails";
import useRetrieveFunctions from "../../CustomHooks/useRetrieveFunctions";
import { TransportFareData } from "../../Store/Reducers/data_menu";

// ===================================================|| EDIT TRANSPORT DETAILS ||=================================================== //

export const EditTransportingDetails = ({
  billingCommonProps,
  transportInfo,
}) => {
  const {
    isArrowIcon,
    setIsArrowIcon,
    billUpdateError,
    setBillUpdateError,
    updateClick,
    setUpdateClick,
  } = billingCommonProps;
  const {
    dummyOrder,
    setDummyOrder,
    handleUpdate,
    handleCancel,
    customerResponsibility,
    setCustomerResponsibility,
    selectedTransport,
    setSelectedTransport,
    setGatePassData,
    gatePassData,
    setExpandedRows,
    expandedRows,
    selectedCustomer,
  } = transportInfo;

  const {
    labels: { customerResponsibilityText, transportText },
    buttonText: { noText, yesText },
  } = uiTexts;

  const dispatch = useDispatch();

  const { retrieveTransportFareDetails } = useRetrieveFunctions();

  const [isTotalQuantityMatched, setIsTotalQuantityMatched] = useState(false);

  useEffect(() => {
    const allMatched = gatePassData?.every((order, orderIndex) => {
      const totalQuantity = calculateTotalQuantity(gatePassData, orderIndex);
      const allErrorsCleared = order.gatePassDetails?.every(
        (detail) => detail.quantityError === "" && detail.quantity !== "",
      );

      return (
        totalQuantity === getPendingBagOrQuantity(order) && allErrorsCleared
      );
    });

    setIsTotalQuantityMatched(allMatched);

    const area = getFirstPartUpperCase(selectedCustomer?.partyArea);
    retrieveTransportFareDetails(area, selectedTransport?.transportName);
  }, [gatePassData]);

  const handleFieldChange = (field, value) => {
    switch (field) {
      case customerResponsibilityText: {
        setCustomerResponsibility(value);
        value === yesText
          ? setSelectedTransport()
          : setSelectedTransport(selectedTransport);

        break;
      }

      case transportText:
        if (value) {
          setBillUpdateError("");
          dispatch(TransportFareData([]));
          setSelectedTransport(value);
          const area = getFirstPartUpperCase(selectedCustomer?.partyArea);
          retrieveTransportFareDetails(area, value?.transportName);
        } else {
          setSelectedTransport();
        }
        break;

      default:
        break;
    }
  };

  const isUpdateDisabled = () => {
    const isCustomerResponsible = customerResponsibility !== "";
    const isCustomerNotResponsible = customerResponsibility === noText;
    const isTransportNotSelected =
      selectedTransport === "" || selectedTransport === undefined;
    const hasBillUpdateError = billUpdateError !== "";
    const isQuantityNotMatched = !isTotalQuantityMatched;

    if (!isCustomerResponsible) {
      return false;
    }

    if (isCustomerNotResponsible) {
      return (
        isTransportNotSelected || hasBillUpdateError || isQuantityNotMatched
      );
    }

    return hasBillUpdateError || isQuantityNotMatched;
  };

  const updateDisabled = isUpdateDisabled();

  return (
    <div className="bill-modal-scrollable-content">
      <SourceAndDestinationDetails selectedCustomer={selectedCustomer} />

      <TransportInputFields
        customerResponsibility={customerResponsibility}
        selectedTransport={selectedTransport}
        handleFieldChange={handleFieldChange}
        isEditBillModal={true}
        setBillUpdateError={setBillUpdateError}
      />

      <TransportDetailsTable
        dummyOrder={dummyOrder}
        setDummyOrder={setDummyOrder}
        handleFieldChange={handleFieldChange}
        customerResponsibility={customerResponsibility}
        setGatePassData={setGatePassData}
        gatePassData={gatePassData}
        setExpandedRows={setExpandedRows}
        expandedRows={expandedRows}
        selectedTransport={selectedTransport}
        isEditBillModal={true}
        setBillUpdateError={setBillUpdateError}
      />

      <TransportDetailsActions
        handleUpdate={handleUpdate}
        isUpdateDisabled={updateDisabled}
        handleCancel={handleCancel}
        updateClick={updateClick}
        setUpdateClick={setUpdateClick}
        isArrowIcon={isArrowIcon}
        setIsArrowIcon={setIsArrowIcon}
      />

      {billUpdateError && (
        <Typography className="modal-error-text">{billUpdateError}</Typography>
      )}
    </div>
  );
};
