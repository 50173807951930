import React from "react";
import { Paper, Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { formatAmount } from "../../Utils/common";
import Chart from "react-apexcharts";
import "../Analysis.css";

// ==============================================|| COMMODITY BAR CHART ||============================================= //

export const OrderChartDetails = ({ orderedDetailsData, selectedCategory }) => {
  const {
    chartLabels: {
      orderedDetailsText,
      billedBagsText,
      quantityBagText,
      barText,
      roundedText,
      itemTypeText,
    },
    labels: { orderedBagsText, commodityText },
    headerLabels: { pendingBagsText },
    alertLabels: { noDataText },
    separators: { ellipsisText },
  } = uiTexts;

  const filteredData = orderedDetailsData?.filter(
    (item) =>
      item.orderedBags > 0 || item.billedBags > 0 || item.pendingBags > 0,
  );

  const orderedBagsData = filteredData?.map((item) => item.orderedBags) || [];
  const billedBagsData =
    filteredData?.map((item) =>
      item.orderedBags === item.billedBags ? null : item.billedBags,
    ) || [];
  const pendingBagsData = filteredData?.map((item) => item.pendingBags) || [];

  const chartLabels =
    selectedCategory === ""
      ? filteredData?.map((item) => item.categoryName || "").filter(Boolean)
      : filteredData?.map((item) => item.itemType || "").filter(Boolean);

  const chartData = {
    labels: chartLabels,
    series: [
      {
        name: orderedBagsText,
        data: orderedBagsData,
      },
      {
        name: billedBagsText,
        data: billedBagsData?.filter((item) => item !== null),
      },
      {
        name: pendingBagsText,
        data: pendingBagsData,
      },
    ],
  };

  const colorCodes = ["#0195FE", "#4BB58F", "#FFCF01"];

  const options = {
    chart: {
      type: barText,
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        endingShape: roundedText,
      },
    },
    xaxis: {
      tickAmount: Math.min(chartLabels?.length, 5),
      categories: chartData?.labels,
      title: {
        text: selectedCategory === "" ? commodityText : itemTypeText,
        style: {
          color: "var(--analysis-chart-label-color)",
        },
      },
      labels: {
        formatter: (value) => {
          const maxLength = 7;
          if (value.length > maxLength) {
            return value.substring(0, maxLength) + ellipsisText;
          }
          return value;
        },
      },
    },
    yaxis: {
      title: {
        text: quantityBagText,
        style: {
          color: "var(--analysis-chart-label-color)",
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${formatAmount(val)}`,
      },
      x: {
        formatter: (value) => {
          const fullLabel = chartData?.labels.find((label) => label === value);
          return fullLabel || value;
        },
      },
    },
    colors: colorCodes,
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <Paper className="chart-summary-background">
      <Typography className="chart-header-text">
        {orderedDetailsText}
      </Typography>

      {chartData?.labels?.length === 0 ? (
        <div className="no-data-text">
          <Typography>{noDataText}</Typography>
        </div>
      ) : (
        <Chart
          options={options}
          series={chartData?.series}
          type={barText}
          width="450"
          height="350"
        />
      )}
    </Paper>
  );
};
