import React from "react";
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";
import { TransportFareData } from "../../../Store/Reducers/data_menu";
import { useDispatch } from "react-redux";

// ========================================|| CUSTOMER RESPONSIBILITY ||============================================= //

export const CustomerResponsibility = ({
  customerResponsibility,
  handleFieldChange,
  isEditBillModal,
  setBillUpdateError,
}) => {
  const {
    labels: { customerResponsibilityText },
    buttonText: { yesText, noText },
  } = uiTexts;

  const dispatch = useDispatch();

  const handleRadioChange = (e) => {
    dispatch(TransportFareData([]));
    setBillUpdateError("");
    handleFieldChange(customerResponsibilityText, e.target.value);
  };
  
  return (
    <>
      <Grid item xs={2.5}>
        <Typography className="bill-modal-text">
          {customerResponsibilityText}
        </Typography>
      </Grid>

      <Grid item xs={3.5}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={isEditBillModal ? customerResponsibility : noText}
            name="radio-buttons-group"
            row
            onChange={(e) => handleRadioChange(e)}
          >
            <FormControlLabel
              value={yesText}
              control={<Radio />}
              label={yesText}
            />

            <FormControlLabel
              value={noText}
              control={<Radio />}
              label={noText}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};
