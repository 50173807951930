import React from "react";
import { Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";

// ==========================================|| KPI DETAILS HEADER||=========================================== //

export const KpiHeaderDetails = ({
  headerText,
  subHeaderText,
  isSubheader,
}) => {
  const {
    separators: { leftParenthesisText, rightParenthesisText },
  } = uiTexts;

  return (
    <Typography className="details-header">
      {headerText}

      {isSubheader && (
        <Typography component="span" className="details-sub-header">
          {leftParenthesisText}
          {subHeaderText}
          {rightParenthesisText}
        </Typography>
      )}
    </Typography>
  );
};
