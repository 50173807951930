import React from "react";
import { Grid, Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { HeaderGrid } from "../../CommonComp/HeaderGrid";

// ============================================|| STOCK LHS LIST HEADER ||============================================= //

export const StockLhsListHeader = ({ filteredCategories }) => {
  const {
    labels: { sNoText, categoryText },
  } = uiTexts;

  return (
    <Grid container className="customer-list-header">
      <Grid item xs={3}>
        <Typography className="customer-list-header-text">{sNoText}</Typography>
      </Grid>

      <HeaderGrid
        text={categoryText}
        xs={9}
        count={filteredCategories?.length}
      />
    </Grid>
  );
};
