import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http.common";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");

// ========================================|| DATA SERVICE ||======================================== //

export const getAll = () => {
  return apiService.get("/getAllSalesData", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllStates = () => {
  return apiService.get("/states/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllItems = () => {
  return apiService.get("/items/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllBrands = () => {
  return apiService.get("/brands/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllCategories = () => {
  return apiService.get("/categories/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllCustomers = () => {
  return apiService.get("/customers/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllFirms = () => {
  return apiService.get("/firms/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getCustomerOrderHistoryById = (id) => {
  return apiService.get(`/customers/${id}/order-history`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getCustomerDetailsById = (id) => {
  return apiService.get(`/customers/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getBillsByDate = async (date) => {
  return apiService.get(`/bills/byDate?date=${date}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getCustomerGroup = async (id) => {
  return apiService.get(`/customer-groups/remaining-party-ids/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllTransportData = () => {
  return apiService.get("/transports/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllDispatchReportData = () => {
  return apiService.get("/gatePass/completedList", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllSalesParametersData = () => {
  return apiService.get("/salesParameters/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllCleanCategories = () => {
  return apiService.get("/cleanCategories/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllUnits = () => {
  return apiService.get("/units/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};
