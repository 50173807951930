import React from "react";
import { Grid, Typography, ListItem, Button } from "@mui/material";
import { capitalizeFirstLowercaseRest } from "../../Utils/common";
import { uiTexts } from "../../Utils/uiTexts";
import storeIcon from "../../Image/storeIcon.png";
import "../../CustomersTab/CustomerPage.css";
import "../../EditBills/editbills.css";

// ============================================|| EDIT BILLS LHS LIST ITEM ||============================================ //

export const EditBillsLhsListItem = ({
  searchList,
  setSelectedCustomer,
  selectedCustomer,
  filteredCustomersData,
}) => {
  const {
    separators: { hyphenText },
  } = uiTexts;

  const handleCustomerSelection = (party) => {
    setSelectedCustomer(party);
  };

  const GridItem = ({ xs, children, showIcon }) => {
    return (
      <Grid item xs={xs}>
        <Typography className="customer-details-list-text">
          {showIcon && (
            <img src={storeIcon} className="storeIcon-style" alt="logo" />
          )}
          {children}
        </Typography>
      </Grid>
    );
  };

  return (
    <div className="list-container pending-bill-list-height">
      {filteredCustomersData
        ?.sort((a, b) => b.pendingBills - a.pendingBills)
        .map((party) => {
          return (
            <Button
              key={party.partyId}
              className={`orders-payment-content--button ${
                selectedCustomer?.partyId === party.partyId &&
                "orders-payment-content--button--selected"
              }`}
              onClick={() => handleCustomerSelection(party)}
            >
              <ListItem>
                <Grid container>
                  <GridItem xs={9.5} showIcon>
                    {party.partyName}
                    {party.partyArea &&
                      ` ${hyphenText} ${capitalizeFirstLowercaseRest(
                        party.partyArea,
                      )}`}
                  </GridItem>

                  <GridItem xs={2.5}>{party.pendingBills}</GridItem>
                </Grid>
              </ListItem>
            </Button>
          );
        })}
    </div>
  );
};
