import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// =======================================|| STOCK UPDATE MODAL DATE AND TIME ||===================================== //

export const StockUpdateModalDateAndTime = ({
  selectedDate,
  setSelectedDate,
  handleStockDateSelect,
}) => {
  const {
    placeHolderTexts: { chooseDateAndTimeText, dateTimeDisplayFormat },
  } = uiTexts;

  return (
    <Grid container spacing={5} className="order-details-modal-table">
      <Grid item xs={5}>
        <Typography className="bill-modal-text">
          {chooseDateAndTimeText}
        </Typography>
      </Grid>

      <Grid item xs={7} className="custom-date-picker">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={chooseDateAndTimeText}
            value={selectedDate}
            format={dateTimeDisplayFormat}
            onChange={(newValue) =>
              handleStockDateSelect(newValue, setSelectedDate)
            }
            renderInput={(params) => <TextField {...params} fullWidth />}
            slotProps={{
              textField: {
                readOnly: true,
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};
