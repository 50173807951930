import React from "react";
import {
  MenuItem,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

// ======================================|| STOCK UPDATE MODAL DROPDOWN ||======================================== //

export const StockUpdateModalDropdown = ({
  typographyText,
  value,
  handleChange,
  menuOptions,
  keyId,
  keyValue,
}) => {
  return (
    <Grid container spacing={5} className="order-details-modal-table">
      <Grid item xs={5}>
        <Typography className="bill-modal-text">{typographyText}</Typography>
      </Grid>

      <Grid item xs={7}>
        <FormControl variant="outlined">
          <InputLabel>{typographyText}</InputLabel>

          <Select
            label={typographyText}
            value={value ?? ""}
            onChange={(e) => handleChange(e)}
            style={{ width: "260px" }}
          >
            {menuOptions?.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option[keyValue]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
