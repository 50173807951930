import React from "react";
import { deleteOrderItemById } from "../../Services/OrderService";
import useRetrieveFunctions from "../../CustomHooks/useRetrieveFunctions";
import { ConfirmationModal } from "../../CommonComp/ConfirmationModal";
import { uiTexts } from "../../Utils/uiTexts";

// =========================================|| ORDER DELETE MODAL ||======================================== //

export const OrderDeleteModal = ({
  setIsOpenRemoveConfirmationModal,
  isOpenRemoveConfirmationModal,
  handleCloseConfirmationModal,
  selectTransactionId,
  orderItems,
  setOrderItems,
  setSelectedCustomer,
}) => {
  const {
    labels: { areYouSureYouWantToDeleteThisOrderText },
  } = uiTexts;

  const { retrieveCustomerPendingOrders } = useRetrieveFunctions();

  // Function remove order item
  const removeOrderItem = async (selectTransactionId) => {
    const remainingOrderItems = orderItems?.filter(
      (order) => order.id !== selectTransactionId,
    );
    const itemToDelete = orderItems?.find(
      (order) => order.id === selectTransactionId,
    );

    if (itemToDelete) {
      await deleteOrderItemById(selectTransactionId);
    }

    setOrderItems(remainingOrderItems);

    if (remainingOrderItems?.length === 0) {
      setSelectedCustomer();
    }

    await retrieveCustomerPendingOrders();

    handleCloseConfirmationModal();
  };

  return (
    <ConfirmationModal
      isOpenModal={isOpenRemoveConfirmationModal}
      handleCloseModal={handleCloseConfirmationModal}
      removeItem={() => removeOrderItem(selectTransactionId)}
      modalHeaderText={areYouSureYouWantToDeleteThisOrderText}
    />
  );
};
