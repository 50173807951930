import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http.common";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");

// ========================================|| ORDER SERVICE ||======================================== //

export const getAllOrders = () => {
  return apiService.get("/orders", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllCustomerPendingOrders = () => {
  return apiService.get("/customers/pending-orders", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllOrderItemTransactions = () => {
  return apiService.get("orderItemTransactions/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getOrderItemTransactionById = (id) => {
  return apiService.get(`/orderItemTransactions/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getByItemName = (name) => {
  return apiService.get(`/ordersItemNameBased/${name}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const moveToBill = (data) => {
  return apiService.post(`/orders/moveToBill`, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllItemWiseOrders = () => {
  return apiService.get("orders/ordersItemWise", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getItemWiseOrderById = (id) => {
  return apiService.get(`orders/ordersItemWise/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const deleteOrderItemById = (transactionId) => {
  return apiService.delete(`orderItemTransactions/${transactionId}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllBillsData = () => {
  return apiService.get("bills/getAll", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getAllPendingBillsData = () => {
  return apiService.get("/bills/pendingBills", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const editBill = (data, id) => {
  return apiService.put(`/bills/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getFareDetails = (areaName, transportName) => {
  return apiService.get(
    `/transportFare/getUnitAndFare?areaName=${areaName}&transportName=${transportName}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: "Basic " + encodedCredentials,
      },
    },
  );
};
