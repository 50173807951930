import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// ==========================================|| KPI TABLE HEADER ||=========================================== //

export const KpiDetailsTableHeader = ({
  tableHeaderText,
  negativeValuesCount,
}) => {
  const {
    labels: { averageText },
    separators: { leftParenthesisText, rightParenthesisText },
    chartLabels: { daysText },
    warnings: { countOfNegativeAverageText },
  } = uiTexts;

  const renderNegativeCount = () => (
    <Typography
      component="span"
      className="details-sub-header details-sub-header-error"
    >
      <Tooltip title={countOfNegativeAverageText}>
        <div className="has-missing-data-dot has-missing-data-dot-error" />
        {leftParenthesisText}
        {negativeValuesCount}
        {rightParenthesisText}
      </Tooltip>
    </Typography>
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell className="remove-border-bottom kpi-table-cell-padding">
          <Typography className="bold-text">{tableHeaderText}</Typography>
        </TableCell>

        <TableCell className="remove-border-bottom kpi-table-cell-padding">
          <Typography className="bold-text">
            {averageText}
            <Typography component="span" className="details-sub-header">
              {leftParenthesisText}
              {daysText}
              {rightParenthesisText}
            </Typography>

            {negativeValuesCount !== 0 && renderNegativeCount()}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
