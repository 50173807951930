import React from "react";
import { Table, TableContainer } from "@mui/material";
import { StockRhsContentTableHeader } from "./StockRhsContentTableHeader";
import { StockRhsContentTableItem } from "./StockRhsContentTableItem";

// ===================================|| STOCK RHS CONTENT TABLE ||============================================ //

export const StockRhsContentTable = ({
  updatedItemData,
  handleOpenEditStockModal,
  handleRemoveStock,
}) => {
  return (
    <TableContainer className="customer-order-details-header list-container order-details-page-height stock-rhs-content-table-width">
      <Table stickyHeader>
        <StockRhsContentTableHeader />

        <StockRhsContentTableItem
          updatedItemData={updatedItemData}
          handleOpenEditStockModal={handleOpenEditStockModal}
          handleRemoveStock={handleRemoveStock}
        />
      </Table>
    </TableContainer>
  );
};
