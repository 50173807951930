// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  customerStatusCode: false,
  analysisStatusCode: false,
  customerPendingOrderStatusCode: false,
  orderItemTransactionsDataStatusCode: false,
  customerPendingStatusCode: false,
  itemWiseOrderedStatusCode: false,
  categoryStatusCode: false,
  billsByDateStatusCode: false,
  transportFareStatus: false,
  stockByCategoryStatus: false,
};

// ==============================|| SLICE - STATUS MENU ||============================== //

const status_menu = createSlice({
  name: "status_menu",
  initialState,
  reducers: {
    CustomerStatusCode(state, action) {
      state.customerStatusCode = action.payload;
    },

    AnalysisStatusCode(state, action) {
      state.analysisStatusCode = action.payload;
    },

    CustomerPendingOrderStatusCode(state, action) {
      state.customerPendingOrderStatusCode = action.payload;
    },

    OrderItemTransactionsDataStatusCode(state, action) {
      state.orderItemTransactionsDataStatusCode = action.payload;
    },

    CustomerPendingStatusCode(state, action) {
      state.customerPendingStatusCode = action.payload;
    },

    ItemWiseOrderedStatusCode(state, action) {
      state.itemWiseOrderedStatusCode = action.payload;
    },

    CategoryStatusCode(state, action) {
      state.categoryStatusCode = action.payload;
    },

    BillsByDateStatusCode(state, action) {
      state.billsByDateStatusCode = action.payload;
    },

    TransportFareStatus(state, action) {
      state.transportFareStatus = action.payload;
    },

    StockByCategoryStatus(state, action) {
      state.stockByCategoryStatus = action.payload;
    },
  },
});

export default status_menu.reducer;

export const {
  CustomerStatusCode,
  AnalysisStatusCode,
  CustomerPendingOrderStatusCode,
  OrderItemTransactionsDataStatusCode,
  CustomerPendingStatusCode,
  ItemWiseOrderedStatusCode,
  CategoryStatusCode,
  BillsByDateStatusCode,
  TransportFareStatus,
  StockByCategoryStatus,
} = status_menu.actions;
