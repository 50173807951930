import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import { uiTexts } from "../../Utils/uiTexts";

// ==============================================|| BRAND WISE ORDER DETAILS ||============================================= //

export const BrandWiseOrdersChart = ({ brandOrderDetails }) => {
  const {
    chartLabels: { brandWiseDetailsText, pieText, unitNameText, ofOrdersText },
    alertLabels: { noDataText },
    labels: { categoryText },
    separators: { percentageText },
  } = uiTexts;

  const [chartData, setChartData] = useState({
    labels: [],
    series: [],
    tooltipData: [],
  });

  useEffect(() => {
    setChartData({
      labels: [],
      series: [],
      tooltipData: [],
    });
  }, []);

  useEffect(() => {
    if (brandOrderDetails) {
      const newChartData = brandOrderDetails?.reduce(
        (acc, item) => {
          if (item.brandName && item.ordersTaken) {
            acc.labels.push(item.brandName);
            acc.series.push(item.ordersTaken);
            acc.tooltipData.push(item.details || []);
          }
          return acc;
        },
        { labels: [], series: [], tooltipData: [] },
      );
      setChartData(newChartData);
    }
  }, [brandOrderDetails]);

  const options = {
    chart: {
      type: pieText,
    },
    labels:
      Array.isArray(chartData?.labels) && chartData?.labels?.length > 0
        ? chartData?.labels
        : [],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(0)}%`,
      dropShadow: {
        enabled: false,
      },
    },
    tooltip: {
      theme: "light",
      custom: function ({ seriesIndex, w }) {
        const brandDetails = Array.isArray(chartData?.tooltipData[seriesIndex])
          ? chartData?.tooltipData[seriesIndex]
          : [];

        if (brandDetails?.length === 0) {
          return "";
        }

        let tooltipContent = `<div><strong>${w.globals?.labels[seriesIndex]}</strong></div>`;
        tooltipContent += `<table class="tooltip-table">`;
        tooltipContent += `<thead>
                              <tr>
                                <th>${unitNameText}</th>
                                <th>${categoryText}</th>
                                <th>${percentageText} ${ofOrdersText}</th>
                              </tr>
                           </thead>`;
        tooltipContent += `<tbody>`;
        brandDetails?.forEach((detail) => {
          tooltipContent += `
            <tr>
              <td>${detail.unitName || ""}</td>
              <td>${detail.cleanCategoryName || ""}</td>
              <td>${
                detail.ordersTaken ? detail.ordersTaken + percentageText : ""
              }</td>
            </tr>`;
        });
        tooltipContent += `</tbody>`;
        tooltipContent += `</table>`;

        return tooltipContent;
      },
    },
  };

  return (
    <Paper className="brand-summary-background commoditywise-orders-style">
      <Typography className="chart-header-text">
        {brandWiseDetailsText}
      </Typography>

      <div className="donut-chart">
        {chartData?.labels?.length === 0 ? (
          <div className="no-data-text brand-no-data-text">
            <Typography>{noDataText}</Typography>
          </div>
        ) : (
          <Chart
            options={options}
            series={
              Array.isArray(chartData?.series) && chartData?.series.length > 0
                ? chartData?.series
                : []
            }
            type={pieText}
            width="280"
            height="200"
          />
        )}
      </div>
    </Paper>
  );
};
