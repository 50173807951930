import React, { useState } from "react";
import { Grid, Divider } from "@mui/material";
import { StockLhsListHeader } from "./StockLhsListHeader";
import { StockLhsListItem } from "./StockLhsListItem";
import { StockRhs } from "../StockRhsContent/StockRhs";
import useRetrieveFunctions from "../../CustomHooks/useRetrieveFunctions";
import { uiTexts } from "../../Utils/uiTexts";
import dayjs from "dayjs";
import "../../OrdersTab/OrderPage.css";

// ==============================================|| STOCK LHS LIST ||================================================ //

export const StockLhsList = ({
  filteredCategories,
  selectedCategory,
  setSelectedCategory,
}) => {
  const {
    placeHolderTexts: { dateAndTimeInputFormat },
  } = uiTexts;

  const [filteredDate, setFilteredDate] = useState(null);
  const [updatedItemData, setUpdatedItemData] = useState([]);

  const { retrieveStockByCategory } = useRetrieveFunctions();

  const handleSelectCategory = (category) => {
    setUpdatedItemData([]);
    setSelectedCategory(category);
    setFilteredDate();

    retrieveStockByCategory(category?.id);
  };

  const handleStockDateSelect = (value, setDate) => {
    const formattedDate = dayjs(value).format(dateAndTimeInputFormat);
    setDate(formattedDate);
  };

  return (
    <Grid container className="order-payment-page-area order-list">
      <Grid item xs={3} className="order-grid">
        <StockLhsListHeader filteredCategories={filteredCategories} />

        <Divider className="customer-list-header-divider" />

        <StockLhsListItem
          selectedCategory={selectedCategory}
          handleSelectCategory={handleSelectCategory}
          filteredCategories={filteredCategories}
        />
      </Grid>

      {selectedCategory && (
        <StockRhs
          selectedCategory={selectedCategory}
          handleStockDateSelect={handleStockDateSelect}
          setFilteredDate={setFilteredDate}
          filteredDate={filteredDate}
          updatedItemData={updatedItemData}
          setUpdatedItemData={setUpdatedItemData}
        />
      )}
    </Grid>
  );
};
