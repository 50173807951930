import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";
import { SearchField } from "../../CommonComp/SearchField";
import { StockLhsList } from "./StockLhsList";
import "../../OrdersTab/OrderPage.css";

// =============================================|| STOCK LHS CONTENT ||============================================== //

function StockLhsContent() {
  const {
    placeHolderTexts: { searchCategory },
    warnings: { categoryNotFoundText },
  } = uiTexts;

  const { categoryList } = useSelector((state) => state.data_menu);

  const [searchList, setSearchList] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();

  const filteredCategories = categoryList?.filter((category) =>
    category.categoryName.toLowerCase().includes(searchList.toLowerCase()),
  );

  const isEmptyData = filteredCategories?.length === 0;

  return (
    <Stack direction="column">
      <div className="customer-search-container-order">
        <SearchField
          searchList={searchList}
          setSearchList={setSearchList}
          searchLabel={searchCategory}
          setSelectedData={setSelectedCategory}
        />
      </div>

      {isEmptyData ? (
        <Typography className="order-customer-not-found">
          {categoryNotFoundText}
        </Typography>
      ) : (
        <StockLhsList
          filteredCategories={filteredCategories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      )}
    </Stack>
  );
}

export default StockLhsContent;
