import React, { useState } from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { formatDate } from "../../Utils/common";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { ConfirmationModal } from "../../CommonComp/ConfirmationModal";
import { handleUnknown } from "../../Utils/common";

// =======================================|| STOCK RHS CONTENT TABLE ITEM ||=========================================== //

export const StockRhsContentTableItem = ({
  updatedItemData,
  handleOpenEditStockModal,
  handleRemoveStock,
}) => {
  const {
    separators: { hyphenText },
    labels: { kgText, areYouSureYouWantToDeleteThisStockText, unKnownText },
  } = uiTexts;

  const [isDeleteStock, setIsDeleteStock] = useState(false);
  const [deleteStockData, setDeleteStockData] = useState();

  const TableCellContent = ({ content }) => (
    <TableCell className="orders-table-cell-style">
      <Typography className="commodity-details-text">
        {content ? content : hyphenText}
      </Typography>
    </TableCell>
  );

  const handleRemoveSelectedStock = () => {
    handleRemoveStock(deleteStockData);
    setIsDeleteStock(false);
  };

  return (
    <>
      <TableBody>
        {updatedItemData?.map((items, index) => (
          <>
            <TableRow>
              <TableCellContent content={index + 1} />

              <TableCellContent content={formatDate(items?.date)} />

              <TableCellContent
                content={handleUnknown(unKnownText, items?.itemName)}
              />

              <TableCellContent
                content={handleUnknown(unKnownText, items?.brandName)}
              />

              <TableCellContent
                content={handleUnknown(unKnownText, items?.cleanCategoryName)}
              />

              <TableCellContent
                content={handleUnknown(unKnownText, items?.unitListName)}
              />

              <TableCellContent
                content={
                  items.totalBags
                    ? items.totalBags
                    : items?.quantityInKg && items.quantityInKg + " " + kgText
                }
              />

              <TableCell className="orders-table-cell-style">
                <IconButton onClick={() => handleOpenEditStockModal(items)}>
                  <EditOutlinedIcon />
                </IconButton>
              </TableCell>

              <TableCell className="orders-table-cell-style">
                <IconButton
                  onClick={() => {
                    setDeleteStockData(items);
                    setIsDeleteStock(true);
                  }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </>
        ))}
      </TableBody>

      {isDeleteStock && (
        <ConfirmationModal
          isOpenModal={isDeleteStock}
          handleCloseModal={() => setIsDeleteStock(false)}
          removeItem={() => handleRemoveSelectedStock()}
          modalHeaderText={areYouSureYouWantToDeleteThisStockText}
        />
      )}
    </>
  );
};
