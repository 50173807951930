/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { StockUpdateModal } from "../StockUpdateModal/StockUpdateModal";
import { formatDate } from "../../Utils/common";
import { StockEditModal } from "../EditStockModal/StockEditModal";
import { StockRhsContent } from "./StockRhsContent";
import { Loader } from "../../Loader/Loader";
import { deleteStock } from "../../Services/StockService";
import { useDispatch } from "react-redux";
import { CategoryWiseStockData } from "../../Store/Reducers/data_menu";

// =========================================|| STOCK RHS CONTENT ||============================================= //

export const StockRhs = ({
  selectedCategory,
  handleStockDateSelect,
  setFilteredDate,
  filteredDate,
  updatedItemData,
  setUpdatedItemData,
}) => {
  const dispatch = useDispatch();

  const categoryWiseStockData = useSelector(
    (state) => state.data_menu.categoryWiseStockData,
  );
  const stockByCategoryStatus = useSelector(
    (state) => state.status_menu.stockByCategoryStatus,
  );

  const [isOpenStockUpdateModal, setIsOpenStockUpdateModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedItem, setSelectedItem] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedCleanCategory, setSelectedCleanCategory] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const [stockQuantity, setStockQuantity] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [isOpenEditStockModal, setIsOpenEditStockModal] = useState(false);
  const [editedItemData, setEditedItemData] = useState();

  useEffect(() => {
    if (filteredDate === null || filteredDate === undefined) {
      setUpdatedItemData(categoryWiseStockData);
    }
  }, [categoryWiseStockData]);

  const getFilteredItems = (data) => {
    const filteredItems = data?.filter(
      (item) => formatDate(item.date) === formatDate(filteredDate),
    );

    filteredItems?.length === 0 && filteredDate === null
      ? setUpdatedItemData(categoryWiseStockData)
      : setUpdatedItemData(filteredItems);
  };

  useEffect(() => {
    getFilteredItems(categoryWiseStockData);
  }, [filteredDate]);

  const handleOpenStockUpdateModal = () => {
    setIsOpenStockUpdateModal(true);
  };

  const handleCloseStockUpdateModal = () => {
    setIsOpenStockUpdateModal(false);
    setSelectedItem();
    setSelectedBrand();
    setSelectedCleanCategory();
    setSelectedUnit();
    setStockQuantity("");
    setQuantityError("");
    setSelectedDate(dayjs());
  };

  const handleOpenEditStockModal = (items) => {
    setIsOpenEditStockModal(true);
    setEditedItemData(items);
  };

  const handleCloseEditStockModal = () => {
    setIsOpenEditStockModal(false);
    setEditedItemData();
  };

  const handleClearFilter = () => {
    setFilteredDate(null);
    setUpdatedItemData(categoryWiseStockData);
  };

  const filterDataById = (items, itemId) => {
    return items?.filter((item) => item.id !== itemId);
  };

  const handleRemoveStock = async (items) => {
    //To update redux variable
    const updatedData = filterDataById(categoryWiseStockData, items.id);

    //To update state variable
    //State variable is updated independently (filtering may or may not be applied).
    const filteredUpdatedItemsData = filterDataById(updatedItemData, items.id);

    await deleteStock(items.id).then(({ status }) => {
      if (status === 200) {
        dispatch(CategoryWiseStockData(updatedData));
        setUpdatedItemData(filteredUpdatedItemsData);
      }
    });
  };

  return (
    <>
      {!stockByCategoryStatus && <Loader />}

      <StockRhsContent
        selectedCategory={selectedCategory}
        handleStockDateSelect={handleStockDateSelect}
        handleOpenStockUpdateModal={handleOpenStockUpdateModal}
        filteredDate={filteredDate}
        setFilteredDate={setFilteredDate}
        handleClearFilter={handleClearFilter}
        updatedItemData={updatedItemData}
        handleOpenEditStockModal={handleOpenEditStockModal}
        handleRemoveStock={handleRemoveStock}
      />

      {isOpenStockUpdateModal && (
        <StockUpdateModal
          isOpenStockUpdateModal={isOpenStockUpdateModal}
          selectedCategory={selectedCategory}
          handleCloseStockUpdateModal={handleCloseStockUpdateModal}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleStockDateSelect={handleStockDateSelect}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          setSelectedBrand={setSelectedBrand}
          selectedBrand={selectedBrand}
          setSelectedCleanCategory={setSelectedCleanCategory}
          selectedCleanCategory={selectedCleanCategory}
          setSelectedUnit={setSelectedUnit}
          selectedUnit={selectedUnit}
          setStockQuantity={setStockQuantity}
          stockQuantity={stockQuantity}
          setQuantityError={setQuantityError}
          quantityError={quantityError}
          setUpdatedItemData={setUpdatedItemData}
          updatedItemData={updatedItemData}
          getFilteredItems={getFilteredItems}
        />
      )}

      {isOpenEditStockModal && (
        <StockEditModal
          isOpenEditStockModal={isOpenEditStockModal}
          handleCloseEditStockModal={handleCloseEditStockModal}
          editedItemData={editedItemData}
          setUpdatedItemData={setUpdatedItemData}
          updatedItemData={updatedItemData}
        />
      )}
    </>
  );
};
