/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
import { KpiHeaderDetails } from "../KpiHeaderDetails";
import { KpiDetailsTableHeader } from "./KpiDetailsTableHeader";
import { uiTexts } from "../../../Utils/uiTexts";

// ==========================================|| KPI TABLE DETAILS ||=========================================== //

export const KpiDetailsTableDetails = ({
  data,
  headerText,
  column1Text,
  column1Key,
  column2Key,
  isSubheader,
}) => {
  const {
    keyLabels: { numberText },
  } = uiTexts;
  const [negativeValuesCount, setNegativeValuesCount] = useState(0);

  useEffect(() => {
    let count = 0;
    data?.forEach((item) => {
      const value = item[column2Key];
      if (typeof value === numberText && value < 0) {
        count++;
      }
    });
    setNegativeValuesCount(count);
  }, [data, column2Key]);

  const KpiDetailsTableCell = ({ label, isNegative }) => {
    return (
      <TableCell
        className="remove-border-bottom kpi-table-cell-padding"
        style={{ color: isNegative ? "var(--common-error-color)" : "inherit" }}
      >
        {label}
      </TableCell>
    );
  };

  return (
    <>
      <KpiHeaderDetails
        headerText={headerText}
        subHeaderText={column1Text}
        isSubheader={isSubheader}
      />

      <TableContainer className="kpi-details-table-style">
        <Table stickyHeader>
          <KpiDetailsTableHeader
            tableHeaderText={column1Text}
            negativeValuesCount={negativeValuesCount}
          />

          <TableBody>
            {data?.map((row, index) => {
              const isNegative =
                typeof row[column2Key] === numberText && row[column2Key] < 0;
              return (
                <TableRow key={index}>
                  <KpiDetailsTableCell
                    label={row[column1Key]}
                    isNegative={isNegative}
                  />
                  <KpiDetailsTableCell
                    label={row[column2Key]}
                    isNegative={isNegative}
                  />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
