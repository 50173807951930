/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Typography, Grid, Tooltip } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { useSelector } from "react-redux";
import useRetrieveFunctions from "../../CustomHooks/useRetrieveFunctions";

// ==========================================|| KPI CARDS ||=========================================== //

export const KpiCards = ({
  selectedKpi,
  setSelectedKpi,
  startDate,
  endDate,
}) => {
  const {
    kpiCardsLabels: {
      orderToBillAverageText,
      billToDispatchAverageText,
      billToPaymentAverageText,
      dispatchToDeliveryAverageText,
    },
    placeHolderTexts: { dateFormat },
    separators: { hyphenText, leftParenthesisText, rightParenthesisText },
    salesDelayTypeLabels: {
      goodUpperCaseText,
      averageUpperCaseText,
      badUpperCaseText,
    },
    chartLabels: { daysText },
  } = uiTexts;

  const { overAllSalesDelayDetails } = useSelector((state) => state.kpi_menu);
  const {
    retrieveOrderToBillDelay,
    retrieveBillToDispatchDelay,
    retrieveBillToPaymentDelay,
  } = useRetrieveFunctions();

  const updatedKpiData = [
    {
      title: orderToBillAverageText,
      details: overAllSalesDelayDetails?.orderToBillDelay,
    },
    {
      title: billToDispatchAverageText,
      details: overAllSalesDelayDetails?.billToDispatchDelay,
    },
    {
      title: billToPaymentAverageText,
      details: overAllSalesDelayDetails?.billToPaymentDelay,
    },
    { title: dispatchToDeliveryAverageText, details: hyphenText },
  ];

  const handleCardClick = (index, kpiData) => {
    const formattedStartDate = startDate ? startDate?.format(dateFormat) : null;
    const formattedEndDate = endDate ? endDate?.format(dateFormat) : null;

    if (index === 0) {
      retrieveOrderToBillDelay(formattedStartDate, formattedEndDate);
    } else if (index === 1) {
      retrieveBillToDispatchDelay(formattedStartDate, formattedEndDate);
    } else if (index === 2) {
      retrieveBillToPaymentDelay(formattedStartDate, formattedEndDate);
    } else if (index === 3) {
      return;
    }

    setSelectedKpi({ index, data: kpiData });
  };

  const getBorderColor = (selectedKpi, salesDelayType, index) => {
    if (selectedKpi?.index !== index) {
      return "2px solid white";
    }

    switch (salesDelayType) {
      case goodUpperCaseText:
        return "2px solid var(--common-color)";
      case averageUpperCaseText:
        return "2px solid var(--kpi-average-color)";
      case badUpperCaseText:
        return "2px solid var(--kpi-bad-color)";
      default:
        return "2px solid white";
    }
  };

  const KpiCard = ({ index, title, kpiData }) => {
    const areDatesValid =
      (startDate === null && endDate === null) || (startDate && endDate);

    return (
      <Tooltip title={kpiData?.salesDelayType}>
        <div
          className="kpi-cards-div-style"
          onClick={() => areDatesValid && handleCardClick(index, kpiData)}
          style={{
            border: getBorderColor(selectedKpi, kpiData?.salesDelayType, index),
            cursor: index !== 3 && "pointer",
          }}
        >
          <Typography className="kpi-card-header">{title}</Typography>

          <Typography component="span" className="details-sub-header">
            {leftParenthesisText}
            {daysText}
            {rightParenthesisText}
          </Typography>

          <Typography className="kpi-card-text">
            {areDatesValid
              ? kpiData?.averageDays !== undefined
                ? kpiData?.averageDays
                : ""
              : hyphenText}
          </Typography>
        </div>
      </Tooltip>
    );
  };

  return (
    <Grid item spacing={2} className="kpi-flex-row kpi-flex-wrap kpi-card-gap">
      {updatedKpiData?.map((data, idx) => (
        <KpiCard
          key={idx}
          index={idx}
          title={data?.title}
          kpiData={data?.details?.length > 0 ? data?.details[0] : hyphenText}
        />
      ))}
    </Grid>
  );
};
