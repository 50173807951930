import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";

// ======================================|| STOCK RHS CONTENT HEADER ||======================================== //

export const StockRhsContentHeader = ({
  selectedCategory,
  handleOpenStockUpdateModal,
}) => {
  const {
    buttonText: { updateText },
    labels: { categoryText },
  } = uiTexts;

  const HeaderDetail = ({ gridSize, className, text }) => (
    <Grid item xs={gridSize}>
      <Typography className={className}>{text}</Typography>
    </Grid>
  );

  return (
    <Grid
      container
      className="customer-order-header-details-style"
      alignItems="center"
    >
      <HeaderDetail
        gridSize={2}
        className="customer-header-list-text"
        text={categoryText}
      />
      <HeaderDetail
        gridSize={7}
        className="customer-header-details-list-text"
        text={selectedCategory?.categoryName}
      />

      <Grid item xs={3} container justifyContent="flex-end">
        <Button
          className="moveToBill-button moveToBill-button-bg-color"
          onClick={() => handleOpenStockUpdateModal()}
        >
          {updateText}
        </Button>
      </Grid>
    </Grid>
  );
};
